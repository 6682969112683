import ProductsService from "@/services/products.service";

const initialState = { deals: undefined, dealsForUser: undefined, selectedProduct: undefined };

export const products = {
  namespaced: true,
  state: initialState,
  actions: {
    setSelectedProduct({ commit }, payload = null) {
      commit("setSelectedProduct", payload)
    },
    getTokenProducts({ commit }) {
      const products = JSON.parse(localStorage.getItem("products"));
      if (products) {
        commit("getAllTokenProducts", products)
      }

      return ProductsService.getProducts().then(
        (products) => {
          commit("getAllTokenProducts", products.data);
          localStorage.setItem("products", JSON.stringify(products.data));
          return Promise.resolve(products.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    setSelectedProduct(state, product) {
      state.selectedProduct = product
    },
    getAllTokenProducts(state, products) {
      state.deals = products;
    }
  },
};
