import authHeader from "./auth-header";
import API_URL from "@/globals/api";
import axios from "axios";

class LogService {
    lastUsed(email) {
        return axios.post(
            `${API_URL.API_URL}/logs/update`,
            {
                email: email,
                timestamp: Date.now()
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    ...authHeader(),
                },
            }
        );
    }
}

export default new LogService();
