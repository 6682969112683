<script setup>
import { computed, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import menu from "@/menu.js";
import NavBar from "@/components/NavBar.vue";
import AsideMenu from "@/components/AsideMenu.vue";
import FooterBar from "@/components/FooterBar.vue";
import Overlay from "@/components/Overlay.vue";
import { VueNotificationList } from '@dafcoe/vue-notification'
import logsService from "@/services/logs-service";

const store = useStore();

store.commit("user", {
  name: "John Doe",
  email: "john@example.com",
  // avatar:
  // `https://api.dicebear.com/5.x/identicon/svg?seed=${store.auth..username.replace(/[^a-z0-9]+/i, '-')}`,
});

const isLoggedIn = computed(() => reactive(store.state.auth.status.loggedIn));

const email = computed(() => store?.state?.auth?.user?.user?.email)

const isAsideLgActive = computed(() => store.state.isAsideLgActive);

const darkMode = computed(() => store.state.darkMode)

const overlayClick = ()  => {
  store.dispatch("asideLgToggle", false);
};

const verified = computed(() => store?.state?.auth?.user?.user?.email_verified_at)

const filteredLinks = menu.filter(item => {
  if (typeof item === 'string') {
    return item !== 'Verify';
  } else if (Array.isArray(item) && item.length > 0 && item[0].to === '/verify') {
    return false;
  }
  return true;
});

const configuredMenu = computed(() => {
  if (verified.value) {
    return filteredLinks
  } else {
    return menu
  }
})

onMounted(async() => {
  if (isLoggedIn.value) {
    await logsService.lastUsed(email.value)
  }
})

</script>

<template>

  <vue-notification-list></vue-notification-list>

  <nav-bar v-if="isLoggedIn" />
  <aside-menu :menu="configuredMenu" />
  
  <router-view />
  <footer-bar />
  <overlay v-show="isAsideLgActive" z-index="z-30" @overlay-click="overlayClick" />
  <notifications position="bottom left"/>
</template>

