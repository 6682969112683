<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";

const store = useStore();

const darkMode = computed(() => store.state.darkMode);

const props = defineProps({
  displayed: {
    type: Boolean,
    default: false,
    required: true
  },
  text: {
    type: String,
    default: null
  },
  size: {
    type: Number,
    default: 4,
    required: false
  },
  formtype: {
    type: String,
    default: null
  }
});

const style = computed(() => props?.formtype === 'bars' ? `loader` : `w-${props.size} h-${props.size} border-4 border-blue-400 border-solid rounded-full animate-spin`)

</script>

<template>
  <div v-show="props.displayed" :style="`border-top-color: transparent;  ${darkMode ? 'color:white;' : 'color:black;'}`"
    :class="style">
  </div>
</template>

<style></style>