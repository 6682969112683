import axios from "axios";
import store from ".././store";
import authHeader from "./auth-header";
import axiosInstance from "./api";
import router from '@/router';

// const API_URL = 'https://laravel-backend-uuvarjp72q-ew.a.run.app/api/auth/';
const API_URL = window.location.href.includes("localhost")
  ? "http://127.0.0.1:8000/api/auth/"
  : "https://bumper-oo-backend-uuvarjp72q-ew.a.run.app/api/auth/";

class AuthService {
  resendVerify() {
    return axiosInstance.post(API_URL + "email/verification-notification", {});
  }

  login(user) {
    return axios
      .post(API_URL + "login", {
        email: user.email,
        password: user.password,
      })
      .then((response) => {
        if (response.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  /**
   * Set scrcl
   * @param {*} scrcl 
   * @returns 
   */
  setScrcl(scrcl) {
    return axiosInstance.post(
      `${API_URL}scrcl`,
      {
        scrcl
      },
      {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
      }
    );
  }


  /**
   * Fingerprint
   * @returns 
   */
  setFingerprint(fingerprint) {
    return axiosInstance.post(
      `${API_URL}fingerprint`,
      {
        fingerprint
      },
      {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
      }
    );
  }

  getUser() {
    store.dispatch("auth/toggleLoading");
    return axios
      .get(`${API_URL}user-profile`, {
        headers: {
          "Content-Type": "application/json",
          ...authHeader(),
        },
      })
      .then((response) => {
        const user = JSON.parse(localStorage.getItem("user"));
        const token = user.access_token;
        const expires = user.expires_in;
        const token_type = user.token_type;

        const compare = {
          access_token: token,
          expires_in: expires,
          token_type: token_type,
          user: response.data,
        };
        localStorage.setItem("user", JSON.stringify(compare));
        store.dispatch("auth/updateUser", compare.user);
      })
      .catch(async (err) => {
        store.dispatch("auth/toggleLoading");
        await this.refresh();
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  //JSON.stringify(localStorage.getItem("user"))
  refresh() {
    return axios
      .post(
        API_URL + "refresh",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            ...authHeader(),
          },
        }
      )
      .then(async (response) => {
        //First get new token
        const json = JSON.parse(localStorage.getItem("user"));
        const compare = { ...json, ...response.data };
        localStorage.setItem("user", JSON.stringify(compare));

        //Then update user
        await this.getUser();
        return response.data;
      });
  }

  isTokenExpired() {
    const user = localStorage.getItem("user");
    if (user) {
      const token = JSON.parse(user).access_token;
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      const { exp } = JSON.parse(jsonPayload);
      const expired = Date.now() >= exp * 1000;
      // if (expired) {
      //   this.refresh();
      //   // location.reload(true);
      // }
      return expired;
    }
  }

  register(user) {
    return axios.post(API_URL + "register", {
      name: user.username,
      email: user.email,
      password: user.password,
      password_confirmation: user.password_confirmation,
      token: user.token
    });
  }
}

export default new AuthService();
